/* eslint-disable no-use-before-define */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable react/function-component-definition */

import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Checkbox, Flex, Grid, Text, Tooltip } from '@chakra-ui/react';
import ModalComponent from '../Modal';
import { getSiteRestrictions, createSiteRestrictions } from '../../redux/action/association.action';

function SiteSelectionModal({ isOpen, onClose, onSetMargin, associationId, tableRefreash }) {
  const [sites, setSites] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showTooltips, setShowTooltips] = useState({});
  const textRefs = useRef({});

  useEffect(() => {
    if (isOpen) {
      fetchSiteRestrictions();
    }
  }, [isOpen]);

  useEffect(() => {
    // Check text overflow for each site
    sites.forEach((site) => {
      const element = textRefs.current[site.siteId];
      if (element) {
        setShowTooltips((prev) => ({
          ...prev,
          [site.siteId]: element.scrollWidth > element.clientWidth
        }));
      }
    });
  }, [sites]);

  const fetchSiteRestrictions = async () => {
    setIsLoading(true);
    const result = await getSiteRestrictions(associationId);
    if (result.ok) {
      const fetchedSites = result.data.sites.map((site) => ({
        siteName: site.site_name,
        marginStatus: site.is_selected,
        siteId: site.site_id
      }));
      setSites(fetchedSites);
      setSelectAll(fetchedSites.every((site) => site.marginStatus));
    }
    setIsLoading(false);
  };

  const handleSelectAllToggle = (checked) => {
    setSelectAll(checked);
    setSites(sites.map((site) => ({ ...site, marginStatus: checked })));
  };

  const handleSiteToggle = (index) => {
    const newSites = [...sites];
    newSites[index].marginStatus = !newSites[index].marginStatus;
    setSites(newSites);
    setSelectAll(newSites.every((site) => site.marginStatus));
  };

  const handleSetMargin = async () => {
    const selectedSiteIds = sites.filter((site) => site.marginStatus).map((site) => site.siteId);
    const payload = {
      agency_client_association_id: associationId,
      site_ids: selectedSiteIds
    };
    const result = await createSiteRestrictions(payload);
    if (result.ok) {
      tableRefreash();
      onSetMargin(selectedSiteIds);
      onClose();
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Flex justify="center" align="center" minH="200px">
          <Text fontSize="lg" color="gray.500">
            Loading sites...
          </Text>
        </Flex>
      );
    }

    if (!sites.length) {
      return (
        <Flex
          direction="column"
          justify="center"
          align="center"
          minH="200px"
          p={6}
          bg="gray.50"
          borderRadius="md">
          <Text fontSize="xl" fontWeight="medium" color="gray.600" mb={2}>
            No Sites Available
          </Text>
          <Text fontSize="md" color="gray.500" textAlign="center">
            There are currently no sites available for selection.
          </Text>
        </Flex>
      );
    }

    return (
      <>
        <Flex
          bg="gray.50"
          p={4}
          borderRadius="md"
          mb={4}
          alignItems="center"
          justifyContent="space-between">
          <Checkbox
            size="lg"
            isChecked={selectAll}
            onChange={(e) => handleSelectAllToggle(e.target.checked)}
            colorScheme="blue">
            <Text fontWeight="medium">Select All Sites</Text>
          </Checkbox>
          <Text color="gray.600" fontSize="sm">
            Selected: {sites.filter((site) => site.marginStatus).length} / {sites.length}
          </Text>
        </Flex>

        <Box
          paddingTop={4}
          maxH="500px"
          overflowY="auto"
          sx={{
            '&::-webkit-scrollbar': {
              width: '8px',
              borderRadius: '8px',
              backgroundColor: 'rgba(0, 0, 0, 0.05)'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.15)'
              }
            }
          }}>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            {sites.map((site, index) => (
              <Flex
                key={site.siteId}
                p={4}
                bg="white"
                borderRadius="md"
                border="1px"
                borderColor={site.marginStatus ? 'main.primary' : 'gray.200'}
                _hover={{
                  bg: 'gray.50',
                  borderColor: 'main.primary',
                  transform: 'translateY(-1px)'
                }}
                transition="all 0.2s"
                cursor="pointer"
                onClick={() => handleSiteToggle(index)}
                minH="60px"
                minW="200px"
                alignItems="center">
                <Tooltip
                  label={site.siteName}
                  placement="top"
                  hasArrow
                  isDisabled={!showTooltips[site.siteId]}>
                  <Text
                    ref={(el) => (textRefs.current[site.siteId] = el)}
                    noOfLines={1}
                    fontSize="15px"
                    width="320px"
                    cursor="pointer"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap">
                    {site.siteName}
                  </Text>
                </Tooltip>
                <Checkbox
                  size="lg"
                  isChecked={site.marginStatus}
                  colorScheme="blue"
                  pointerEvents="none"
                  ml={2}
                />
              </Flex>
            ))}
          </Grid>
        </Box>
      </>
    );
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      specifySize="4xl"
      onClose={onClose}
      modalTitle="Site Margin Rule's"
      modalContent={<Box p={6}>{renderContent()}</Box>}
      negative={
        <Button
          bg="main.secondary"
          mr={3}
          _hover={{ bg: 'main.secondary' }}
          color="main.black"
          onClick={onClose}>
          Cancel
        </Button>
      }
      positive={
        <Button
          bg="main.semiPrimary"
          color="main.white"
          _hover={{ bg: 'main.primary' }}
          _active={{
            bg: 'main.semiPrimary',
            borderColor: 'main.primary'
          }}
          onClick={handleSetMargin}
          isDisabled={!sites.length}>
          Selected Site ({sites.filter((site) => site.marginStatus).length})
        </Button>
      }
    />
  );
}

export default SiteSelectionModal;
