/* eslint-disable no-use-before-define */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  useToast,
  VStack,
  HStack,
  IconButton,
  Image,
  Flex,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
  Thead,
  Spinner,
  Text
} from '@chakra-ui/react';
import TrainingRuleAddEditForm from '../TrainingRuleAddEditForm';
import DeleteBlueIcon from '../../assets/images/delete-blue-icon.png';
import AddIcon from '../../assets/images/add.png';
import ModalComponent from '../Modal';
import Label from '../Label';
import AlertModalOverlay from '../AlertModalComponent'; // Import the AlertModalOverlay component
import {
  addRule,
  getRules,
  updateRule,
  deleteRule
} from '../../redux/action/workerTraining.action';
import { filterErrorData } from '../../utils/helper';
import { CUSTOM_TOAST_DURATION, CUSTOM_TOAST_DURATION_NULL, TOAST_SETTINGS } from '../../constants';

const TrainingRuleForm = function ({ isOpen, onClose, clientId, tableRefreash }) {
  const toast = useToast();
  const [rules, setRules] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedRule, setSelectedRule] = useState(null);
  const [openTrainingRuleModal, setOpenTrainingRuleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteRuleId, setDeleteRuleId] = useState(null);

  useEffect(() => {
    setRules([]);
    if (isOpen) {
      fetchRules(clientId);
    }
  }, [isOpen, clientId]);

  const fetchRules = async (clientIdForApi) => {
    setLoading(true);
    const result = await getRules(clientIdForApi);
    if (result.ok) {
      setRules(
        result.data.rules.map((rule) => ({
          id: rule.id,
          site: { value: rule.site_id, label: rule.site_name },
          performanceNumber: rule.performance_threshold,
          weekNumber: rule.threshold_week,
          checkWeek: rule.evaluation_week,
          maxTrainingHours: rule.max_training_hours,
          credit: rule.creditRate,
          regionId: rule.region_id
        }))
      );
    } else {
      toast({
        title: 'Error fetching rules',
        status: 'error',
        duration: 3000
      });
    }
    setLoading(false);
  };

  const handleDelete = async (ruleId) => {
    setDeleteRuleId(ruleId);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    const result = await deleteRule(clientId, deleteRuleId);
    if (result.ok) {
      setRules((prevRules) => prevRules.filter((rule) => rule.id !== deleteRuleId));
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION_NULL.duration,
        status: TOAST_SETTINGS.ERROR,
        title: filterErrorData(result.error)
      });
    }
    closeDeleteModal();
  };

  const closeDeleteModal = () => {
    tableRefreash();
    setIsDeleteModalOpen(false);
    setDeleteRuleId(null);
  };

  const handleEdit = (rule) => {
    setSelectedRule(rule);
    setIsEditMode(true);
    setOpenTrainingRuleModal(true);
  };

  const handleAdd = () => {
    setSelectedRule(null);
    setIsEditMode(false);
    setOpenTrainingRuleModal(true);
  };

  const handleSave = async (formData) => {
    const payload = {
      site_id: formData.site.value,
      threshold_week: formData.weekNumber,
      evaluation_week: formData.checkWeek,
      max_training_hours: formData.maxTrainingHours,
      performance_threshold: formData.performanceNumber,
      credit_rate: formData.credit,
      region_id: formData.regionId
    };

    let result;
    if (isEditMode && formData.id) {
      result = await updateRule(clientId, formData.id, payload);
    } else {
      result = await addRule(clientId, payload);
    }

    if (result.ok) {
      if (isEditMode) {
        setRules((prevRules) =>
          prevRules.map((rule) => (rule.id === formData.id ? formData : rule))
        );
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
      } else {
        const newFormData = { ...formData, id: result.data.rule_id };
        setRules((prevRules) => [...prevRules, newFormData]);
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
      }
      setIsEditMode(false);
      setSelectedRule(null);
      setOpenTrainingRuleModal(false);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION_NULL.duration,
        status: TOAST_SETTINGS.ERROR,
        title: filterErrorData(result.error)
      });
    }
  };

  return (
    <>
      <TrainingRuleAddEditForm
        tableRefreash={tableRefreash}
        isOpen={openTrainingRuleModal}
        onClose={() => setOpenTrainingRuleModal(false)}
        isEdit={isEditMode}
        existingData={selectedRule}
        onSave={handleSave}
        clientId={clientId}
      />
      <ModalComponent
        specifySize="6xl"
        isOpen={isOpen}
        onClose={onClose}
        modalTitle="Manage Training Rules"
        modalContent={
          <VStack spacing={4} align="stretch">
            <Flex mb="10px">
              <Image mr="10px" cursor="pointer" src={AddIcon} height={6} onClick={handleAdd} />
              <Label label="Add New Training Rule" color="main.primary" />
            </Flex>
            {loading ? (
              <Flex justify="center" align="center" height="200px">
                <Spinner size="xl" />
              </Flex>
            ) : (
              <Box overflowX="auto">
                <Table variant="simple" size="sm">
                  <Thead>
                    <Tr>
                      <Th px={2} py={1} fontWeight="bold" color="blue.600">
                        Site
                      </Th>
                      <Th px={2} py={1} fontWeight="bold" color="blue.600">
                        Performance Number
                      </Th>
                      <Th px={2} py={1} fontWeight="bold" color="blue.600">
                        Week Number
                      </Th>
                      <Th px={2} py={1} fontWeight="bold" color="blue.600">
                        Check Week
                      </Th>
                      <Th px={2} py={1} fontWeight="bold" color="blue.600">
                        Max Training Hours
                      </Th>
                      <Th px={2} py={1} fontWeight="bold" color="blue.600">
                        Credit(%)
                      </Th>
                      <Th px={2} py={1} fontWeight="bold" color="blue.600">
                        Actions
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {rules.length === 0 ? (
                      <Tr>
                        <Td colSpan="7">
                          <Text align="center">No Rules Available</Text>
                        </Td>
                      </Tr>
                    ) : (
                      rules.map((rule) => (
                        <Tr key={rule.id}>
                          <Td px={2} py={1}>
                            {rule.site.label}
                          </Td>
                          <Td px={2} py={1}>
                            {rule.performanceNumber}
                          </Td>
                          <Td px={2} py={1}>
                            {rule.weekNumber}
                          </Td>
                          <Td px={2} py={1}>
                            {rule.checkWeek}
                          </Td>
                          <Td px={2} py={1}>
                            {rule.maxTrainingHours}
                          </Td>
                          <Td px={2} py={1}>
                            {rule.credit}
                          </Td>
                          <Td px={2} py={1}>
                            <HStack>
                              <IconButton
                                icon={<Image src={DeleteBlueIcon} width="26px" height="26px" />}
                                onClick={() => handleDelete(rule.id)}
                              />
                              <Button onClick={() => handleEdit(rule)}>Edit</Button>
                            </HStack>
                          </Td>
                        </Tr>
                      ))
                    )}
                  </Tbody>
                </Table>
              </Box>
            )}
          </VStack>
        }
        negative=""
        positive=""
      />
      <AlertModalOverlay
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        title="Delete Rule"
        body="Are you sure you want to delete this rule?"
        negative={
          <Button
            onClick={closeDeleteModal}
            bg="main.secondary"
            mr={3}
            _hover={{ bg: 'main.secondary' }}
            color="main.black">
            Cancel
          </Button>
        }
        positive={
          <Button onClick={confirmDelete} colorScheme="blue" ml={3}>
            Delete
          </Button>
        }
      />
    </>
  );
};

export default TrainingRuleForm;
