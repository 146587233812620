/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, useToast } from '@chakra-ui/react';
import { TOAST_SETTINGS, USER_TYPE } from '../../constants';
import { getRegionDropDownData } from '../../redux/action/region.action';
import Dropdown from '../Dropdown';
import {
  selectPerformanceRegion,
  selectPerformanceSite,
  selectPerformanceShift,
  selectPerformanceDepartment
} from '../../redux/actions/performanceDashboard.action';
// Check if the user is an admin
const RegionDropdownHeader = function ({
  validationObj = { isvalid: true },
  refresh = false,
  multiple = false,
  disabled = false,
  isHeader = false
}) {
  const toast = useToast();
  const dispatch = useDispatch();

  const [regionData, setRegionData] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  const { userData, user_details } = useSelector((state) => state.authentication);
  const { selected_client_id, selected_performance_region_id, selected_performance_region_name } =
    useSelector((state) => state.agency);

  const isAdmin = userData.client_id && user_details.user_type_id === USER_TYPE.CLIENT_ADMIN;
  // Fetch region data when client ID changes
  // const getClientId = () => {
  //   switch (user_details.user_type_id) {
  //     case USER_TYPE.CLIENT_ADMIN:
  //       return userData.client_id;
  //     case USER_TYPE.AGENCY_ADMIN:
  //     case USER_TYPE.AGENCY_REGION_ADMIN:
  //     case USER_TYPE.AGENCY_SITE_ADMIN:
  //       return selected_client_id || userData.client_id;
  //     default:
  //       return selected_client_id || userData.client_id;
  //   }
  // };

  useEffect(() => {
    const fetchRegionData = async () => {
      const clientId = !isAdmin ? selected_client_id : userData.client_id;

      if (clientId) {
        const result = await getRegionDropDownData(clientId);

        if (result && result.ok) {
          setRegionData(result.data.region_details);

          // Auto-select first region or "All Regions" based on isHeader
          if (!selected_performance_region_id) {
            if (isHeader) {
              dispatch(selectPerformanceRegion('', 'All Regions'));
            } else if (result.data.region_details.length > 0) {
              const firstRegion = result.data.region_details[0];
              dispatch(selectPerformanceRegion(firstRegion.id, firstRegion.name));
              // Clear site when auto-selecting first region in non-header mode
              dispatch(selectPerformanceSite(null, null));
              dispatch(selectPerformanceShift(null, null));
              dispatch(selectPerformanceDepartment(null, null));
            }
          }
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      }
    };

    fetchRegionData();
  }, [selected_client_id, userData.client_id, isHeader, selected_performance_region_id, dispatch]);

  // Handle validation changes
  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh, validationObj]);

  // Handle region selection
  const handleRegionSelect = (selectedOption) => {
    // Always clear dependent selections when region changes
    dispatch(selectPerformanceSite(null, null));
    dispatch(selectPerformanceShift(null, null));
    dispatch(selectPerformanceDepartment(null, null));

    setErrorMsg(null);
    setValid(true);

    // Handle "All Regions" case
    if (selectedOption.value === '') {
      dispatch(selectPerformanceRegion('', 'All Regions'));
      return;
    }

    dispatch(selectPerformanceRegion(selectedOption.value, selectedOption.label));
  };

  // Add "All Regions" option only for header
  const getOptions = () => {
    const options = regionData.map((region) => ({
      label: region.name,
      value: region.id
    }));

    if (isHeader) {
      return [{ value: '', label: 'All Regions' }, ...options];
    }
    return options;
  };

  // Get the current value for the dropdown
  const getCurrentValue = () => {
    if (selected_performance_region_name) {
      return {
        label: selected_performance_region_name,
        value: selected_performance_region_id
      };
    }
    if (selected_performance_region_id === '' && isHeader) {
      return { label: 'All Regions', value: '' };
    }
    return {};
  };

  return (
    <>
      <Dropdown
        label={`Region${validationObj?.required ? '*' : ''}`}
        onSelect={handleRegionSelect}
        options={getOptions()}
        value={getCurrentValue()}
        placeholder="Select"
        multiple={multiple}
        isError={!isValid}
        disabled={disabled}
        isSearchable
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error!'}
      </Text>
    </>
  );
};

export default RegionDropdownHeader;
