export const selectPerformanceDashboardType = (dashboardType) => ({
  type: 'SELECT_PERFORMANCE_DASHBOARD_TYPE',
  payload: { dashboard_type: dashboardType }
});

export const selectPerformanceRegion = (regionId, regionName) => ({
  type: 'SELECT_PERFORMANCE_REGION',
  payload: {
    region_id: regionId,
    region_name: regionName
  }
});

export const selectPerformanceSite = (siteId, siteName) => ({
  type: 'SELECT_PERFORMANCE_SITE',
  payload: {
    site_id: siteId,
    site_name: siteName
  }
});

export const selectPerformanceShift = (shiftId, shiftName) => ({
  type: 'SELECT_PERFORMANCE_SHIFT',
  payload: {
    shift_id: shiftId,
    shift_name: shiftName
  }
});

export const selectPerformanceDepartment = (departmentId, departmentName) => ({
  type: 'SELECT_PERFORMANCE_DEPARTMENT',
  payload: {
    department_id: departmentId,
    department_name: departmentName
  }
});
