/* eslint-disable react/function-component-definition */
/* eslint-disable no-use-before-define */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import InputComponent from '../Input';
import { Flex, StyledInputWrapper } from './MarginInputs.styled';
import constants from '../../constants';
import { allowTwoDecimalNumbers } from '../../utils/helper';

const MarginInputs = ({ data, setData, validations, refresh }) => {
  const handleInputChange = (field, value) => {
    setData({
      ...data,
      [field]: value ? allowTwoDecimalNumbers(value).toString() : ''
    });
  };

  return (
    <>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.STANDARD_MARGIN}
            placeholder={constants.STANDARD_MARGIN}
            validationObj={validations.margin || {}}
            onChange={(e) => handleInputChange('margin', e)}
            refresh={refresh}
            value={data.margin || ''}
            type="number"
            min="0"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.OVERTIME_MARGIN}
            placeholder={constants.OVERTIME_MARGIN}
            validationObj={validations.overtime_margin || {}}
            onChange={(e) => handleInputChange('overtime_margin', e)}
            refresh={refresh}
            value={data.overtime_margin || ''}
            type="number"
            min="0"
          />
        </StyledInputWrapper>
      </Flex>

      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.TRANSPORT_FEE}
            placeholder={constants.TRANSPORT_FEE}
            validationObj={validations.transport_fee || {}}
            onChange={(e) => handleInputChange('transport_fee', e)}
            refresh={refresh}
            value={data.transport_fee || ''}
            type="number"
            min="0"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.MISCELLANEOUS}
            placeholder={constants.MISCELLANEOUS}
            validationObj={validations.ssp || {}}
            onChange={(e) => handleInputChange('ssp', e)}
            refresh={refresh}
            value={data.ssp || ''}
            type="number"
            min="0"
          />
        </StyledInputWrapper>
      </Flex>

      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.TRAINING_MARGIN}
            placeholder={constants.TRAINING_MARGIN}
            validationObj={validations.training_margin || {}}
            onChange={(e) => handleInputChange('training_margin', e)}
            refresh={refresh}
            value={data.training_margin || ''}
            type="number"
            min="0"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.BH_MARGIN}
            placeholder={constants.BH_MARGIN}
            validationObj={validations.bh_margin || {}}
            onChange={(e) => handleInputChange('bh_margin', e)}
            refresh={refresh}
            value={data.bh_margin || ''}
            type="number"
            min="0"
          />
        </StyledInputWrapper>
      </Flex>

      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.SP_MARGIN}
            placeholder={constants.SP_MARGIN}
            validationObj={validations.nsp_margin || {}}
            onChange={(e) => handleInputChange('nsp_margin', e)}
            refresh={refresh}
            value={data.nsp_margin || ''}
            type="number"
            min="0"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.INTERNAL_MARGIN}
            placeholder={constants.INTERNAL_MARGIN}
            validationObj={validations.internal_standard_margin || {}}
            onChange={(e) => handleInputChange('internal_standard_margin', e)}
            refresh={refresh}
            value={data.internal_standard_margin || ''}
            type="number"
            min="0"
          />
        </StyledInputWrapper>
      </Flex>

      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.INTERNAL_OVERTIME_MARGIN}
            placeholder={constants.INTERNAL_OVERTIME_MARGIN}
            validationObj={validations.internal_overtime_margin || {}}
            onChange={(e) => handleInputChange('internal_overtime_margin', e)}
            refresh={refresh}
            value={data.internal_overtime_margin || ''}
            type="number"
            min="0"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.INTERNAL_PERMANENT_MARGIN}
            placeholder={constants.INTERNAL_PERMANENT_MARGIN}
            validationObj={validations.internal_permanent_margin || {}}
            onChange={(e) => handleInputChange('internal_permanent_margin', e)}
            refresh={refresh}
            value={data.internal_permanent_margin || ''}
            type="number"
            min="0"
          />
        </StyledInputWrapper>
      </Flex>

      <Flex>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.SUSPENSION_MARGIN}
            placeholder={constants.SUSPENSION_MARGIN}
            validationObj={validations.suspension_margin || {}}
            onChange={(e) => handleInputChange('suspension_margin', e)}
            refresh={refresh}
            value={data.suspension_margin || ''}
            type="number"
            min="0"
          />
        </StyledInputWrapper>
      </Flex>
    </>
  );
};

export default MarginInputs;
