import moment from 'moment';
import AgencyIcon from '../icons/AgencyIcon';
import AssociationIcon from '../icons/AssociationIcon';
import AttendanceIcon from '../icons/AttendanceIcon';
import DepartmentIcon from '../icons/DepartmentIcon';
import HomeIcon from '../icons/HomeIcon';
import RegionIcon from '../icons/RegionIcon';
import RoleIcon from '../icons/RoleIcon';
import ShiftIcon from '../icons/ShiftIcon';
import SiteIcon from '../icons/SiteIcon';
import UserIcon from '../icons/UserIcon';
import RateCardIcon from '../icons/RateCardIcon';
import DemographicsIcon from '../icons/DemographicsIcon';
import EnvelopeIcon from '../icons/EnvelopeIcon';
import ClipboardIcon from '../icons/ClipboardIcon';
import TrendIcon from '../icons/TrendIcon';
import ComplianceIcon from '../icons/ComplianceIcon';

export default {
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  SIGNUP: 'Signup',
  FORGOT: 'Forgot',
  RESET: 'Send Me Instructions',
  HOME: 'Home',
  PROFILE: 'Profile',
  DASHBOARD: 'Dashboard',
  FORGOT_PASSWORD: 'Forgot Password',
  BACK_TO_LOGIN: 'Back To Login',
  UPDATE_PASSWORD: 'Update Password',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  NAME: 'Name',
  NUMBER: 'Contact number',
  ADDRESS1: 'Address line 1',
  ADDRESS2: 'Address line 2',
  ADDRESS3: 'Address line 3',
  CITY: 'Town/city',
  ADD_PERCENTAGE_VALUE: 'Add Percentage Value',
  POST_CODE: 'Post code',
  COUNTRY: 'Country',
  SECTOR: 'Sector',
  SITES: 'Sites',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  DOB: 'DOB',
  NATIONAL_INSURANCE_NUMBER: 'NI Number',
  EMPLOYEE_ID: 'Employee ID',
  TAX_CODE: 'Tax Code',
  NATIONALITY: 'Nationality',
  ORIENTATION: 'Sex',
  START_DATE: 'Start Date',
  TAX_YEAR: 'Tax Year',
  CANCEL: 'Cancel',
  ASSIGNMENT_DATE: 'Assignment Date',
  ADMIN_NAME: 'Admin Name',
  ADMIN_CONTACT_NUMBER: 'Admin Contact Number',
  ADMIN_EMAIL: 'Admin Email',
  CURRENCY: 'Currency',
  PAY_PER_HOUR: 'Pay per Hour',
  NATIONAL_INSURANCE_RATE: 'National Insurance Rate',
  HOLIDAY_PAY_RATE: 'Holiday Pay Rate',
  APPRENTICESHIP_LEVY_RATE: 'Apprenticeship Levy Rate',
  PENSION_RATE: 'Pension Rate',
  FULL_TIME_HOURS_VALUE: 'Full Time Hours Value',
  OVER_TIME_PAY: 'Overtime Pay',
  MARGIN: 'Margin',
  STANDARD_MARGIN: 'Standard Margin',
  OVERTIME_MARGIN: 'Overtime Margin',
  TRANSPORT_FEE: 'Transport Fee',
  BH_MARGIN: 'Bh Margin',
  SP_MARGIN: 'SP Margin',
  INTERNAL_MARGIN: 'Internal Standard Margin',
  INTERNAL_OVERTIME_MARGIN: 'Internal Overtime Margin',
  INTERNAL_PERMANENT_MARGIN: 'Internal Permanent Margin',
  SUSPENSION_MARGIN: 'Suspension Margin',
  TRAINING_MARGIN: 'Training Margin',
  TRAINING_HOURS: 'Training Hours',
  MISCELLANEOUS: 'Miscellaneous',
  ROLE_NAME: 'Role Name',
  HOUR_PER_WEEK: 'Hour Per Week',
  PAYROLL_REF: 'Payroll Reference',
  TRANSPORT: 'Transport',
  OTHER_ASSIGNMENT: 'Other Assignment',
  PENSION_OPT_OUT_STATUS: 'Pension Opt Out',
  HOUSE_NUMBER: 'House Number',
  STUDENT_VISA: 'On Student Visa',
  WORKERS_SUPERVISOR_STATUS: 'Supervisor Status',
  APP_DOWNLOADED: 'App Downloaded',
  UNDER_12_WEEK: 'Under 12 Week',
  POST_12_WEEK: 'Post 12 Week',
  ONE_YEAR: '1 Year',
  REGION: {
    CREATE_BUTTON_TEXT: 'Add Region',
    HEADER_TEXT: 'Add Region',
    SAVE_BUTTON_TEXT: 'Save',
    CANCEL_BUTTON_TEXT: 'Cancel',
    EDIT_BUTTON_TEXT: 'Edit',
    EDIT_UPDATE_BUTTON_TEXT: 'Update',
    EDIT_HEADER_TEXT: 'Edit Region'
  },
  SITE: {
    CREATE_BUTTON_TEXT: 'Add Site',
    HEADER_TEXT: 'Add Site',
    SAVE_BUTTON_TEXT: 'Save',
    CANCEL_BUTTON_TEXT: 'Cancel',
    EDIT_BUTTON_TEXT: 'Edit',
    EDIT_UPDATE_BUTTON_TEXT: 'Update',
    EDIT_HEADER_TEXT: 'Edit Site',
    EDIT_WTR_RULLING: 'Edit WTR Ruling',
    ADD_WTR_RULLING: 'Add WTR Ruling',
    ADD_RC_RULLING: 'Add Rate Card Ruling',
    ERROR_MESSAGE: 'Please Add WTR Ruling'
  },
  DEPARTMENT: {
    CREATE_BUTTON_TEXT: 'Add Department',
    HEADER_TEXT: 'Add Department',
    SAVE_BUTTON_TEXT: 'Save',
    CANCEL_BUTTON_TEXT: 'Cancel',
    EDIT_BUTTON_TEXT: 'Edit',
    EDIT_UPDATE_BUTTON_TEXT: 'Update',
    EDIT_HEADER_TEXT: 'Edit Department'
  },
  SHIFT_TYPE: {
    CREATE_BUTTON_TEXT: 'Add Shift Type',
    HEADER_TEXT: 'Add Shift Type',
    SAVE_BUTTON_TEXT: 'Save',
    CANCEL_BUTTON_TEXT: 'Cancel',
    EDIT_BUTTON_TEXT: 'Edit',
    EDIT_UPDATE_BUTTON_TEXT: 'Update',
    EDIT_HEADER_TEXT: 'Edit Shift Time'
  },
  ROLE_TYPE: {
    CREATE_BUTTON_TEXT: 'Add Role Type',
    BULK_UPLOAD_BUTTON_TEXT: 'Add Bulk Role Type',
    HEADER_TEXT: 'Add Role Type',
    SAVE_BUTTON_TEXT: 'Save',
    CANCEL_BUTTON_TEXT: 'Cancel',
    EDIT_BUTTON_TEXT: 'Edit',
    EDIT_UPDATE_BUTTON_TEXT: 'Update',
    EDIT_HEADER_TEXT: 'Edit Role Type'
  },
  AGENCY: {
    CREATE_BUTTON_TEXT: 'Add Agency',
    HEADER_TEXT: 'Add Agency',
    SAVE_BUTTON_TEXT: 'Save',
    CANCEL_BUTTON_TEXT: 'Cancel',
    EDIT_BUTTON_TEXT: 'Edit',
    EDIT_UPDATE_BUTTON_TEXT: 'Update',
    EDIT_HEADER_TEXT: 'Edit Agency',
    VIEW_USER_BUTTON_TEXT: 'View User',
    RE_INVITE: 'Re-invite User'
  },
  CLIENT: {
    CREATE_BUTTON_TEXT: 'Add Client',
    HEADER_TEXT: 'Add Client',
    SAVE_BUTTON_TEXT: 'Save',
    CANCEL_BUTTON_TEXT: 'Cancel',
    EDIT_BUTTON_TEXT: 'Edit',
    EDIT_UPDATE_BUTTON_TEXT: 'Update',
    EDIT_HEADER_TEXT: 'Edit Client',
    VIEW_USER_BUTTON_TEXT: 'View User',
    RE_INVITE: 'Re-invite User'
  },
  WORKER: {
    CREATE_BUTTON_TEXT: 'Add Worker',
    CREATE_PERMANENT_BUTTON_TEXT: 'Add Permanent Worker',
    UPDATE_PERMANENT_WORKER_BUTTON_TEXT: 'Add/Update Permanent Workers',
    SEARCH_BUTTON: 'Search',
    EDIT_PERMANENT_WORKER_TEXT: 'Edit Permanent Worker',
    BULK_UPLOAD_BUTTON_TEXT: 'Add/Update Bulk Workers',
    BULK_DOWNLOAD_BUTTON_TEXT: 'Download Sample Sheet',
    STATIC_HEADER_TEXT: 'Add/Update Bulk Workers',
    STATIC_UPDATE_HEADER_TEXT: 'Update Worker',
    CONFIRM_BUTTON_TEXT: 'Confirm',
    NEXT_BUTTON_TEXT: 'Next',
    SAVE_BUTTON_TEXT: 'Save',
    CANCEL_BUTTON_TEXT: 'Cancel',
    CLEAR_FILTER_BUTTON_TEXT: 'Clear Filter',
    BACK_BUTTON_TEXT: 'Back',
    EDIT_BUTTON_TEXT: 'Edit',
    EDIT_UPDATE_BUTTON_TEXT: 'Update',
    EDIT_HEADER_TEXT: 'Edit Worker',
    SEARCH_WORKER: 'Search Worker',
    ACTIVE_BUTTON_LABEL: 'Active',
    INACTIVE_BUTTON_LABEL: 'InActive',
    DETAIL: 'Details',
    WORKER_INFORMATION_TEXT: 'Worker Information',
    PERMANENT_WORKER_INFORMATION_TEXT: 'Permanent Worker Information',
    WORKER_EDIT_TEXT: 'Edit Worker',
    PERMANENT_WORKER_EDIT_TEXT: 'Edit Permanent Worker',
    CHANGE_WORKER_STATUS: 'Active/InActive Worker'
  },
  RATE_CARD: {
    CREATE_BUTTON_TEXT: 'Add Rate Card',
    STATIC_HEADER_TEXT: 'Add Static Rate Card',
    DYNAMIC_HEADER_TEXT: 'Add Dynamic Rate Card',
    EDIT_STATIC_HEADER_TEXT: 'Edit Static Rate Card',
    EDIT_DYNAMIC_HEADER_TEXT: 'Edit Dynamic Rate Card',
    SAVE_BUTTON_TEXT: 'Save',
    CANCEL_BUTTON_TEXT: 'Cancel',
    EDIT_BUTTON_TEXT: 'Edit',
    EDIT_UPDATE_BUTTON_TEXT: 'Update',
    EDIT_HEADER_TEXT: 'Edit Rate Card',
    DETAIL: 'Details'
  },
  ASSOCIATION: {
    CREATE_BUTTON_TEXT: 'Add Association',
    HEADER_TEXT: 'Add Association',
    SAVE_BUTTON_TEXT: 'Save',
    CANCEL_BUTTON_TEXT: 'Cancel',
    EDIT_BUTTON_TEXT: 'Edit',
    EDIT_UPDATE_BUTTON_TEXT: 'Update',
    EDIT_HEADER_TEXT: 'Edit Association'
  },
  ATTENDANCE: {
    CANCEL_BUTTON_TEXT: 'Cancel',
    ATTENDANCE_UPLOAD_HEADER_TEXT: 'Payroll',
    ATTENDANCE_UPLOAD_BUTTON_TEXT: 'Confirm',
    VIEW_BUTTON_TEXT: 'View',
    BULK_DOWNLOAD_BUTTON_TEXT: 'Download Sample',
    PROCESS_BUTTON_TEXT: 'Processed Data',
    CSV_BUTTON_TEXT: 'CSV',
    ADD_BUTTON_TEXT: 'Add',
    TAP_BUTTON_TEXT: 'Total Assignment Pay',
    UPDATE_TAP_TEXT: 'Payroll (Updating Total Agency Pay)',
    DELETE_PAYROLL: 'Delete Payroll',
    CONFIRM_BUTTON_TEXT: 'Confirm'
  },
  SHIFT: {
    CREATE_BUTTON_TEXT: 'Add Shift',
    HEADER_TEXT: 'Add Shift',
    SAVE_BUTTON_TEXT: 'Save',
    CANCEL_BUTTON_TEXT: 'Cancel',
    EDIT_BUTTON_TEXT: 'Edit',
    EDIT_UPDATE_BUTTON_TEXT: 'Update',
    EDIT_HEADER_TEXT: 'Edit Shift'
  },
  CLIENT_USERS: {
    CREATE_BUTTON_TEXT: 'Add User',
    HEADER_TEXT: 'Add User',
    SAVE_BUTTON_TEXT: 'Save',
    CANCEL_BUTTON_TEXT: 'Cancel',
    EDIT_BUTTON_TEXT: 'Edit',
    EDIT_UPDATE_BUTTON_TEXT: 'Update',
    EDIT_HEADER_TEXT: 'Edit User',
    RE_INVITE: 'Re-invite User',
    REVOKE: 'Revoke'
  },
  SHIFT_BOOKING_CSV_UPLOAD: {
    HEADER_TEXT: 'Create Booking Via CSV',
    FULLFILL_POPUP_HEADER_TEXT: 'Fulfill Booking Via CSV',
    CONFIRM_BUTTON_TEXT: 'Confirm',
    ADD_CSV_BUTTON_TEXT: 'Add CSV File'
  },
  SHIFT_BOOKING: {
    CREATE_BUTTON_TEXT: 'Add Booking',
    BOOK_VIA_CSV_UPLOAD_BUTTON: 'Book Via CSV Upload',
    UPLOAD_BULK_FULFILLMENT_TEXT: 'Upload Bulk Fulfillment',
    HEADER_TEXT: 'Create Booking',
    AGENCY_MODAL_HEADER_TEXT: 'Assign Agency',
    SAVE_BUTTON_TEXT: 'Create Booking',
    CANCEL_BUTTON_TEXT: 'Cancel',
    CONFIRM_BUTTON_TEXT: 'Confirm',
    EDIT_BUTTON_TEXT: 'Edit',
    EDIT_UPDATE_BUTTON_TEXT: 'Update',
    EDIT_HEADER_TEXT: 'Edit User',
    NEXT_BUTTON_TEXT: 'Next',
    BACK_BUTTON_TEXT: 'Back',
    DELETE_BUTTON_TEXT: 'Delete',
    BOOKING_DETAILS_HEARER_TEXT: 'Booking Details',
    DETAILS_BUTTON_TEXT: 'Details',
    FULFILL_BUTTON_TEXT: 'Fulfill',
    ALL_READY_SELECTED: 'Agency already selected.'
  },
  SHIFT_BOOKING_POPUP_TITLE: 'How It Works ?',
  FULLFILL_BOOKING_POPUP_NOTE: 'Note :',
  FULFILL_BOOKING_DISABLED_NOTE:
    'Fulfillment not allowed after Time and Attendance uploads for selected week.',
  FULLFILL_BOOKING_POPUP_DATA: [
    'Select a <b>date range spanning one or multiple weeks</b> and download the .csv file by clicking the <b>Download</b> button below.',
    'The <b>file will contain the required heads</b> for a specific client across various sites, departments, regions and shifts.',
    '<b>Modify the headcount</b> in the <b>same sheet/duplicate</b> sheet while keeping other values unchanged.',
    "Upload the updated sheet and click <b>'confirm'</b> to complete the shift fulfillment process.",
    "Shift fulfillment is based on the <b>'ID'</b> field. Please do not change the <b>'ID'</b> field value. The columns <b>(client name, site, department, region, and shift)</b> are for reference only."
  ],
  CREATE_BOOKING_POPUP_DATA: [
    'Download the .csv file using the <b>Download</b> button below.',
    'The sheet contains a list of <b>Agency Id</b> and <b>Agency names</b> to assist you in requesting heads from the agency. Ensure the correct combination of <b>agency_id</b> and <b>agency_name</b> values in each row for your head request.',
    'Fill up the number of heads and other necessary details in the same sheet.',
    "Upload the updated sheet and click <b>'confirm'</b> to book the shift."
  ],
  DASHBOARD_VIEW: {
    CANCEL_BUTTON_TEXT: 'Clear',
    OK_BUTTON_TEXT: 'Ok',
    ACTIVITY_FOR_COMPANY: 'Activity of Company',
    WORKFORCE_FOR_COMPANY: 'Workforce of Company',
    LEAVERS_FOR_COMPANY: 'Leavers for Company',
    ACTIVITY_FOR_AGENCIES: 'Activity of Agencies',
    WORKFORCE_FOR_AGENCIES: 'Workforce of Agencies',
    LEAVERS_FOR_AGENCIES: 'Leavers for Agencies'
  },
  MESSAGING: {
    COMPOSE_BUTTON_TEXT: 'Compose',
    DESIGNS_BUTTON_TEXT: 'Designs',
    COMPOSE_MESSAGE_TITLE: 'Compose Message',
    UPDATE_MESSAGE_TITLE: 'Update Message',
    MESSAGE_DESIGNS_TITLE: 'Message Designs',
    NEW_PAGE_BUTTON_TEXT: 'Add New Page',
    SAVE_BTN_LABEL: 'Save',
    SEND_BTN_LABEL: 'Send',
    UPDATE_BTN_LABEL: 'Update',
    CANCEL_BTN_LABEL: 'Cancel',
    TRANSLATE_BTN_LABEL: 'Translate',
    TRANSLATE_ALL_LABEL: 'Translate All',
    EDIT_BUTTON_TEXT: 'Edit',
    DELETE_BUTTON_TEXT: 'Delete',
    UPDATE_EXISTING: 'Update Existing',
    CREATE_NEW: 'Create New'
  },
  MARGINS: '/margins',
  SITE_RESTRICTIONS: '/site-restrictions'
};

export const USER_TYPE = {
  SUPER_ADMIN: 1,
  CLIENT_ADMIN: 2,
  AGENCY_ADMIN: 3,
  REGION_ADMIN: 4,
  SITE_ADMIN: 5,
  AGENCY_REGION_ADMIN: 8,
  AGENCY_SITE_ADMIN: 7,
  MESSAGE_ADMIN: 9
};

export const CLIENT_USER_TYPE = [
  {
    name: 'Region Admin',
    id: 4
  },
  {
    name: 'Site Admin',
    id: 5
  },
  {
    name: 'Message Admin',
    id: 9
  }
];
export const AGENCY_USER_TYPE = [
  {
    name: 'Region Admin',
    id: 4
  },
  {
    name: 'Site Admin',
    id: 5
  }
];

export const DIMENSIONS = {
  LEFT_PANEL_WIDTH: '200px'
};

export const CURRENCY_DATA = [{ label: 'GBP', value: 'DBP' }];

// export const ROLE_TYPES = [
//   { name: 'Full Time', id: '1' },
//   { name: 'Part Time', id: '2' },
//   { name: 'Weekend', id: '3' }
// ];

export const MESSAGE_TYPES = [
  { value: 'GENERAL', name: 'General' },
  { value: 'RECOGNITION', name: 'Recognition' },
  { value: 'AWARD', name: 'Award' },
  { value: 'REWARD', name: 'Reward' },
  { value: 'TRAINING', name: 'Training' },
  { value: 'BADGE', name: 'Badge' }
];
export const TYPES = [
  { value: 'SENT', name: 'Sent' },
  { value: 'AUTOMATED', name: 'Automated' }
];

// left panel items
export const PATH = {
  LOGIN: { TO: '/', CODE: 'all' },
  DASHBOARD: {
    TO: '/dashboard',
    LABEL: 'Dashboard',
    ICON: <HomeIcon />,
    CODE: 'dashboard'
  },
  REGION: {
    TO: '/region',
    LABEL: 'Region',
    ICON: <RegionIcon />,
    CODE: 'region'
  },
  SITES: {
    TO: '/sites',
    LABEL: 'Sites',
    ICON: <SiteIcon />,
    CODE: 'site'
  },
  SHIFT: {
    TO: '/shift',
    LABEL: 'Shift',
    ICON: <ShiftIcon />,
    CODE: 'shift_type'
  },
  DEPARTMENT: {
    TO: '/department',
    LABEL: 'Department',
    ICON: <DepartmentIcon />,
    CODE: 'department'
  },
  ROLE: {
    TO: '/role',
    LABEL: 'Roles',
    ICON: <RoleIcon />,
    CODE: 'job'
  },
  WORKER: {
    TO: '/worker',
    LABEL: 'Workers',
    ICON: <DepartmentIcon />,
    CODE: 'worker'
  },
  DEMOGRAPHICS: {
    LABEL: 'Demographics',
    TO: '/demographics',
    CODE: 'demographics',
    ICON: <DemographicsIcon />
  },
  AGENCY_CLIENT: {
    LABEL: 'Associations',
    ICON: <AssociationIcon />,
    TO: '/agency-client',
    CODE: 'manage_associations'
  },
  TIME_AND_ATTENDANCE: {
    LABEL: 'Payroll',
    ICON: <AttendanceIcon />,
    TO: '/time-and-attendance',
    CODE: 'time_and_attendance'
  },
  SHIFT_BOOKING: {
    LABEL: 'Shift booking',
    ICON: <ShiftIcon />,
    TO: '/shift-booking',
    CODE: 'shift_booking'
  },
  CLIENT_USERS: {
    LABEL: 'System Users',
    ICON: <UserIcon />,
    TO: '/users',
    CODE: 'user'
  },
  AGENCY: {
    TO: '/agency',
    LABEL: 'Agency',
    ICON: <AgencyIcon />,
    CODE: 'agency'
  },
  CLIENT: {
    TO: '/client',
    LABEL: 'Clients',
    ICON: <RegionIcon />,
    CODE: 'client'
  },
  FORGOT_PASSWORD: { TO: '/forgot-password', CODE: 'all' },
  RESET_PASSWORD: { TO: '/reset-password', CODE: 'all' },
  RATE_CARD: {
    LABEL: 'Rate Card',
    ICON: <RateCardIcon />,
    TO: '/rate-card',
    CODE: 'rate_card'
  },
  TIME_AND_ATTENDANCE_DETAILS: {
    LABEL: '',
    ICON: <AttendanceIcon />,
    TO: '/time-and-attendance-details',
    CODE: 'time_and_attendance'
  },
  MESSAGING: {
    LABEL: 'Messaging',
    ICON: <EnvelopeIcon />,
    TO: '/messaging',
    CODE: 'message'
  },
  SURVEYS: {
    LABEL: 'Surveys',
    ICON: <ClipboardIcon />,
    TO: '/surveys',
    CODE: 'survey'
  },
  PROCESSED_ATTENDANCE: {
    LABEL: '',
    ICON: <AttendanceIcon />,
    TO: '/time-and-attendance-processed-data',
    CODE: 'time_and_attendance_processed_data'
  },
  VIEW_MORE: {
    LABEL: '',
    ICON: <HomeIcon />,
    TO: '/view-more',
    CODE: 'all'
  },
  NOT_FOUND_PAGE: {
    LABEL: '',
    ICON: null,
    TO: '*',
    CODE: 'all'
  },
  TREND_ANALYSIS: {
    LABEL: 'Trend Analysis',
    ICON: <TrendIcon />,
    TO: '/trend-analysis',
    CODE: 'trend_analysis'
  },
  PAYROLL: {
    LABEL: 'Payroll',
    ICON: <AttendanceIcon />,
    TO: '/payroll-data',
    CODE: 'payroll_data'
  },
  SURVEY_UPDATE: {
    LABEL: '',
    ICON: '',
    TO: '/edit-general-surveys',
    CODE: 'all'
  },
  COMPLIANCE: {
    TO: '/compliance',
    LABEL: 'Compliance',
    ICON: <ComplianceIcon />,
    CODE: 'compliance'
  },
  AGENCY_MANAGEMENT: {
    TO: '/agency-management',
    LABEL: 'Agency Management',
    ICON: <DepartmentIcon />,
    CODE: 'agency_management'
  },
  TRAINING: {
    TO: '/training',
    LABEL: 'Training',
    ICON: <ComplianceIcon />,
    CODE: 'training'
  }
};

export const API_ENDPOINT = {
  MARGINS: '/margins',
  SITE_RESTRICTIONS: '/site-restrictions',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  GET_USER_DATA: '/user',
  ADD_USER_DATA: '/user',
  UPDATE_USER_DATA: '/user',
  RE_INVITE: '/user/resend-invitation',
  IN_ACTIVE: 'user/status',
  GET_CLIENT: '/client',
  ADD_CLIENT: '/client',
  BLOCK_WORKER_INVITE_EMAIL: '/client/worker-invite-email',
  GET_CLIENT_USER: '/admin-users',
  GET_AGENCY: '/agency',
  GET_AGENCY_USER: '/admin-users',
  ADD_AGENCY: '/agency',
  GET_DEPARTMENT: '/department',
  ADD_DEPARTMENT: '/department',
  UPDATE_DEPARTMENT: '/department',
  GET_REGION: '/region',
  ADD_REGION: '/region',
  UPDATE_REGION: '/region',
  GET_ROLE_TYPE: '/job',
  ADD_ROLE_TYPE: '/job',
  BULK_ROLE_TYPE_UPLOAD: '/job/bulk',
  UPDATE_ROLE_TYPE: '/job',
  GET_ROLE_TYPE_DROP_DOWN: '/job/site',
  GET_ROLE_TYPE_DROP_DOWN_MESSAGING: '/job-drop-down/site',
  GET_SITE: '/site',
  ADD_SITE: '/site',
  UPDATE_SITE: '/site',
  GET_SECTOR: '/sector',
  ADD_SECTOR: '/sector',
  GET_workers: '/workers',
  SEARCH_workers: '/workers/search',
  ADD_WORKER: '/workers',
  ADD_WORKER_DOCS: '/worker/documents',
  UPDATE_WORKER: '/workers',
  BULK_WORKER_UPLOAD: '/workers/bulk',
  BULK_WORKER_DOWNLOAD_SAMPLE: '/get-sample-sheet',
  BULK_T_A_DOWNLOAD_SAMPLE: '/time-and-attendance/get-sample-sheet',
  GET_ASSOCIATIONS: '/agency-client',
  ADD_ASSOCIATIONS: '/agency-client',
  UPDATE_ASSOCIATIONS: '/agency-client',
  RESRICT: '/restrict',
  TAP: '/tap',
  RESRICT_COMMENTS: '/restrict-comments',
  GET_RATE_CARD: '/rate-card',
  GET_RATE_CARD_DROP_DOWN: '/rate-card-drop-down',
  ADD_RATE_CARD: '/rate-card',
  UPDTAE_RATE_CARD: '/rate-card',
  GET_CLIENT_PATH: '/client/',
  GET_WORKER_PERFORMANCE_DOWNLOAD_SAMPLE: '/workers-performance/get-sample-sheet',
  GET_RULES: '/workers-training/rules',
  POST_RULES: '/workers-training/rules',
  UPDATE_RULE: '/workers-training/rules/',
  ATTENDANCE: '/time-and-attendance',
  ATTENDANCE_PROCESS_DATA: '/payroll',
  UPOLAD_T_AND_A_DATA: '/time-and-attendance-upload',
  GET_CLIENT_USERS: '/client-users',
  ADD_CLIENT_USERS: '/client/users',
  UPDATE_CLIENT_USERS: '/client/users',
  GET_AGENCY_USERS: '/agency-users',
  ADD_AGENCY_USERS: '/agency/users',
  UPDATE_AGENCY_USERS: '/agency/users',
  GET_SHIFT: '/shift',
  ADD_SHIFT: '/shift',
  UPDATE_SHIFT: '/shift',
  GET_BOOKING: '/bookings',
  ADD_BOOKING: '/bookings',
  SHIFT_BOOKING: '/shift-booking',
  SHIFT_FULLFILLMENT: '/fulfillment',
  UPDATE_BOOKING: '/booking',
  BULK_BOOKING: '/bookings',
  OPEN: '/open',
  BULK: '/bulk',
  BOOKING_DOWNLOAD: '/download',
  RATE_CARD: '/rate-card',
  RATE_CARD_DOWNLOAD_SAMPLE: '/rate-card/sample-sheet',
  DYNAMIC_SAMPLE_SHEET: '/dynamic-sample-sheet',
  CANCEL_BOOKING: '/booking/cancel',
  ADMIN_ANALYTICS: '/dashboard/analytics',
  ADMIN_CLIENTS: '/dashboard/clients',
  ADMIN_AGENCIES: '/dashboard/agencies',
  ADMIN_SECTORS: '/dashboard/sectors',
  GET_RATING_DATA: '/dashboard/ratings',
  GET_SITE_RATING_DATA: '/site-ratings',
  GET_SITE_RATING_DATA_DETAILS: '/site-ratings/details',
  GET_AGENCY_RATING_DATA: '/agency-ratings',
  GET_AGENCY_RATING_DATA_DETAILS: '/agency-ratings/details',
  GET_CLIENT_RATING_DATA: '/client-ratings',
  GET_CLIENT_RATING_DATA_DETAILS: '/type-wise-client-rating',
  GET_SITE_RATING_DASHBOARD_DATA_DETAILS: '/type-wise-site-rating',
  GET_HEADER_DATA: '/dashboard/header-stats',
  GET_LEAVER_ANALYSIS_DATA: '/dashboard/leavers-analysis',
  GET_ACTIVITY_TP_CARD_DATA: '/dashboard/activity-stats',
  GET_WORKFORCE_TOP_CARD_DEMOGRAPHICS: '/dashboard/worker-demographics',
  GET_WORKFORCE_TOP_CARD_LOS: '/dashboard/worker-service-length',
  GET_WORKFORCE_TOP_CARD_SHIFT_UTI: '/dashboard/workforce/day-wise-shift-utilization',
  GET_WORKFORCE_TOP_CARD_POOL_UTI: '/dashboard/workforce/pool-utilization',
  GET_LEAVER_TOP_CARD_LOS: '/dashboard/leaver-service-length',
  GET_LEAVER_TOP_CARD_COUNTS: '/dashboard/leaver-count-and-starter-retention',
  // GET_LEAVER_TOP_CARD_SHIFT_UTI: '/dashboard/leaver-shift-utilization',
  GET_LEAVER_TOP_CARD_POOL_UTI: '/dashboard/pool-utilization',
  GET_ACTIVITY_BOTTOM_CARD_SHIFT_UTI: '/dashboard/activity/shift-details',
  // GET_ACTIVITY_BOTTOM_CARD_LOST_SHIFT: '/dashboard/activity/shift-lost',
  GET_ACTIVITY_BOTTOM_CARD_HEAD_COUNT: '/dashboard/activity/head-count',
  GET_ACTIVITY_BOTTOM_CARD_SPEND: '/dashboard/activity/spend',
  GET_ACTIVITY_BOTTOM_CARD_AVERAGE_HOURS: '/dashboard/activity/average-hours',
  // GET_WORKFORCE_BOTTOM_CARD_DEMO: '/dashboard/workforce/agency-wise-worker-demographics',
  GET_WORKFORCE_BOTTOM_CARD_SHIFT_UTL: '/dashboard/workforce/agency-wise-shift-utilization',
  GET_WORKFORCE_BOTTOM_CARD_LOS: '/dashboard/workforce/agency-wise-length-of-service',
  GET_LEAVER_BOTTOM_CARD_NSR: '/dashboard/agency-wise-new-starter-retention',
  GET_LEAVER_BOTTOM_CARD_COUNT: '/dashboard/leavers-data',
  // GET_LEAVER_BOTTOM_CARD_SHIFT_UTL: '/dashboard/agency-wise-leaver-shift-utilization',
  GET_LEAVER_BOTTOM_CARD_LOS: '/dashboard/agency-wise-leaver-service-length',
  REVOKE: 'revoke',
  GET_PAYROLL: '/payroll',
  WORKER_PERFORMANCE: '/workers-performance',
  GET_PAYROLL_CSV_DATA: '/payroll-download',
  GET_PAYROLL_DOWNLOAD_SAMPLE: '/payroll-get-sample-sheet',
  GET_TOTAL_AGENCY_PAY: '/total-agency-pay',
  DELETE_TAP_SHEET: '/delete',
  TAP_SHEET_DOWNLOAD: '/download',
  UPLOAD_TAP_DATA: '/upload',
  GET_SAMPLE_SHEET: '/get-sample-sheet',
  GET_DEMOGRAPHICS_PROXIMITY: '/dashboard/proximity',
  GET_DEMOGRAPHICS_GENDER: '/dashboard/gender',
  GET_DEMOGRAPHICS_AGE: '/dashboard/age',
  GET_MESSAGES: '/messages',
  TRANSLATE_MESSAGES: '/translate',
  TEMPLATE_MESSAGE: '/templates',
  TRANSLATE_MULTIPLE: '/multiple',
  GET_TRENDS_SPEND: '/dashboard/trends/spend',
  GET_TRENDS_HOURS: '/dashboard/trends/hours',
  GET_TRENDS_HEADS: '/dashboard/trends/heads',
  GET_TRENDS_LEAVERS: '/dashboard/trends/leavers',
  GET_TRENDS_SITE_RATING: '/dashboard/trends/site-rating',
  GET_TRENDS_AGENCY_RATING: '/dashboard/trends/agency-rating',
  GET_TRENDS_COMPANY_RATING: '/dashboard/trends/company-rating',
  GET_TEMPLATES: '/templates',
  GET_WORKER_LISTING: '/workers-listing',
  GET_SURVEY_CATEGORIES: '/survey/categories',
  GET_SURVEY_ANALYSIS: '/survey/analysis',
  GET_SURVEY_ANALYSIS_QUESTIONS: '/survey/questions',
  GET_SURVEY_ANALYSIS_DOWNLOAD: '/survey/analysis-download',
  GET_WORKER_NATIONALITY: '/workers-nationality',
  GET_PAYROLL_LISTING: '/dashboard/payroll',
  GET_WORKER_CLOCK_REPORT: '/time-and-attendance',
  UPDATE_GENERAL_SURVEY_QUESTIONS: '/survey/questions',
  GET_COMPLIANCE_DATA: '/dashboard/client/',
  COMPLIANCES: '/compliances',
  COMPLIANCE: '/compliances/',
  AGENCY_MANAGEMENT_SUPERVISORS_DATA: '/agency-management/supervisors-data',
  CREDIT_DUES: '/credit-due',
  FTP_MANUAL_UPLOAD_TNA_TRIGGER: '/ftp-tna-lookup-trigger/',
  FTP_MANUAL_UPLOAD_WORKER_TRIGGER: '/ftp-workers-upload-lookup-trigger/'
};

export const REDUX_SELECTOR = {
  USER: {
    LOGIN: 'USER_LOGIN',
    LOGOUT: 'USER_LOGOUT',
    USER_DETAILS: 'USER_DETAILS'
  },
  PROFILE_DETAIL: 'PROFILE_DETAIL',
  TRANSLATE_DATA: 'TRANSLATE_DATA',
  TRANSLATE_ALL_DATA: 'TRANSLATE_ALL_DATA'
};

export const TOKENS = {
  ACCESS: 'access_token',
  REFRESH: 'refresh_token'
};

export const TOAST_SETTINGS = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  SETTINGS: {
    position: 'top',
    duration: '10000',
    isClosable: true,
    variant: 'left-accent'
  }
};

export const CUSTOM_TOAST_DURATION_NULL = {
  duration: null
};

export const CUSTOM_TOAST_DURATION = {
  duration: '4000'
};

export const TRANSLATE_CUSTOM_TOAST_DURATION = {
  duration: '10000'
};

export const ADD_USER_VALIDATIONS = {
  name: {
    required: true,
    errorMessage: '*Please Enter Valid Name',
    isvalid: true
  },
  email: {
    isEmail: true,
    required: true,
    errorMessage: '*Please Enter Valid Email',
    isvalid: true
  },
  country_code: {
    required: true,
    errorMessage: '*Please Enter Valid Country Code',
    isvalid: true
  },
  mobile: {
    required: true,
    errorMessage: '*Please Enter Valid Mobile',
    isvalid: true
  }
};

export const ADD_CLIENT_VALIDATIONS = {
  name: {
    required: true,
    errorMessage: '*Please Enter Valid Client Name',
    isvalid: true
  },
  address_line_1: {
    required: true,
    errorMessage: '*Please Enter Valid Address Line 1',
    isvalid: true
  },
  address_line_2: {
    required: false,
    errorMessage: '*Please Enter Valid Address Line 2',
    isvalid: true
  },
  address_line_3: {
    required: false,
    errorMessage: '*Please Enter Valid Address Line 3',
    isvalid: true
  },
  city: {
    required: true,
    errorMessage: '*Please Enter Valid City',
    isvalid: true
  },
  country: {
    required: true,
    errorMessage: '*Please Enter Valid Country',
    isvalid: true
  },
  weekday_start: {
    required: true,
    errorMessage: '*Please Enter Valid Day',
    isvalid: true
  },
  booking_format: {
    required: true,
    errorMessage: '*Please Enter Valid Format',
    isvalid: true
  },
  sectorId: {
    required: true,
    errorMessage: '*Please Enter Valid Sector',
    isvalid: true
  },
  postCode: {
    required: true,
    errorMessage: '*Please Enter Valid Postal Code',
    isvalid: true
  },
  remote_directory: {
    required: false,
    errorMessage: '*Please Enter Valid File Path',
    isvalid: true
  },
  notification_email: {
    required: false,
    errorMessage: '*Please Enter Valid Email Group',
    isvalid: true
  },
  worker_cron_expression: {
    required: false,
    errorMessage: '*Please Enter Valid Worker Cron Schedule',
    isvalid: true
  },
  worker_ftp: {
    required: false,
    errorMessage: '*Please Select Worker FTP Enabled',
    isvalid: true
  },
  tna_cron_expression: {
    required: false,
    errorMessage: '*Please Enter Valid T&A Cron Schedule',
    isvalid: true
  },
  tna_ftp: {
    required: false,
    errorMessage: '*Please Select T&A FTP Enabled',
    isvalid: true
  },
  ftp_host: {
    required: false,
    errorMessage: '*Please Enter Valid FTP Host',
    isvalid: true
  },
  ftp_username: {
    required: false,
    errorMessage: '*Please Enter Valid FTP Username',
    isvalid: true
  },
  ftp_password: {
    required: false,
    errorMessage: '*Please Enter Valid FTP Password',
    isvalid: true
  }
};

export const ADD_AGENCY_VALIDATIONS = {
  name: {
    required: true,
    errorMessage: '*Please Enter Valid Agency Name',
    isvalid: true
  },
  address_line_1: {
    required: true,
    errorMessage: '*Please Enter Valid Address Line 1',
    isvalid: true
  },
  address_line_2: {
    required: false,
    errorMessage: '*Please Enter Valid Address Line 2',
    isvalid: true
  },
  address_line_3: {
    required: false,
    errorMessage: '*Please Enter Valid Address Line 3',
    isvalid: true
  },
  city: {
    required: true,
    errorMessage: '*Please Enter Valid City',
    isvalid: true
  },
  country: {
    required: true,
    errorMessage: '*Please Enter Valid Country',
    isvalid: true
  },
  postCode: {
    required: true,
    errorMessage: '*Please Enter Valid Postal Code',
    isvalid: true
  }
};

export const LOGIN_VALIDATIONS = {
  email: {
    required: true,
    errorMessage: '*Please enter Email address',
    isvalid: true
  },
  password: {
    required: true,
    errorMessage: '*Please enter Password',
    isvalid: true
  }
};

export const FORGOT_PASSWORD_VALIDATIONS = {
  email: {
    required: true,
    errorMessage: '*Please enter Email address',
    isvalid: true
  }
};

export const RESET_PASSWORD_VALIDATIONS = {
  password: {
    required: true,
    errorMessage: '*Please enter Password',
    isvalid: true
  },
  confirmPassword: {
    required: true,
    errorMessage: '*Please enter Confirm Password',
    isvalid: true
  }
};

export const ADD_SITE_VALIDATIONS = {
  name: {
    required: true,
    errorMessage: '*Please Enter Valid Site Name',
    isvalid: true
  },
  address_line_1: {
    required: true,
    errorMessage: '*Please Enter Valid Address Line 1',
    isvalid: true
  },
  address_line_2: {
    required: false,
    errorMessage: '*Please Enter Valid Address Line 2',
    isvalid: true
  },
  address_line_3: {
    required: false,
    errorMessage: '*Please Enter Valid Address Line 3',
    isvalid: true
  },
  city: {
    required: true,
    errorMessage: '*Please Enter Valid City',
    isvalid: true
  },
  country: {
    required: true,
    errorMessage: '*Please Select Country',
    isvalid: true
  },
  post_code: {
    required: true,
    errorMessage: '*Please Enter Valid Postal Code',
    isvalid: true
  },
  region_id: {
    required: true,
    errorMessage: '*Please Enter Valid Region',
    isvalid: true
  }
};

export const ADD_RULE_VALIDATIONS = {
  rule_name: {
    required: false,
    errorMessage: '*Please Enter Rule Name',
    isvalid: true
  },
  role_type: {
    required: true,
    errorMessage: '*Please Select Role Type',
    isvalid: true
  },
  pay_type: {
    required: true,
    errorMessage: '*Please Select Pay Type',
    isvalid: true
  },
  pre_twelve_week: {
    required: true,
    errorMessage: '*Please Enter Pre Twelve Week Value',
    isvalid: true
  },
  post_twelve_week: {
    required: true,
    errorMessage: '*Please Enter Post Twelve Week Value',
    isvalid: true
  },
  length_of_service: {
    required: false,
    errorMessage: '*Please Select Lenth Of Service',
    isvalid: true
  },
  holiday_days: {
    required: false,
    errorMessage: '*Please Select Holiday Days',
    isvalid: true
  },
  start_tax_year: {
    required: true,
    errorMessage: '*Please Enter Valid Tax Year Date',
    isvalid: true
  }
};

export const ADD_RC_VALIDATIONS = {
  name: {
    required: true,
    errorMessage: '*Please Enter Valid Name',
    isvalid: true
  },
  agency_id: {
    required: true,
    errorMessage: '*Please Select Agency',
    isvalid: true
  }
};
export const ADD_REGION_VALIDATIONS = {
  name: {
    required: true,
    errorMessage: '*Please Enter Valid Region Name',
    isvalid: true
  }
};

export const ADD_DEPARTMENT_VALIDATIONS = {
  name: {
    required: true,
    errorMessage: '*Please Enter Valid Department Name',
    isvalid: true
  }
};

export const ADD_USER_PROFILE_VALIDATIONS = {
  name: {
    required: true,
    errorMessage: '*Please Enter Valid Name',
    isvalid: true
  }
};
export const SEARCH_WORKER_VALIDATIONS = {
  national_insurance_number: {
    required: false,
    errorMessage: '*Please Enter Valid National Insurance Number',
    isvalid: true
  },
  employee_id: {
    required: false,
    errorMessage: '*Please Enter Valid Employee Id',
    isvalid: true
  },

  email: {
    required: false,
    errorMessage: '*Please Enter Valid Email',
    isvalid: true
  },

  payroll_ref: {
    required: false,
    errorMessage: '*Please Enter Valid Payroll Reference',
    isvalid: true
  },
  first_name: {
    required: false,
    errorMessage: '*Please Enter Valid Payroll Reference',
    isvalid: true
  },
  last_name: {
    required: false,
    errorMessage: '*Please Enter Valid Payroll Reference',
    isvalid: true
  },
  pension: {
    required: false,
    errorMessage: '*Please Enter Valid Payroll Reference',
    isvalid: true
  },
  app_downloaded: {
    required: false,
    errorMessage: '*Please Enter Valid Payroll Reference',
    isvalid: true
  },
  other_assignment: {
    required: false,
    errorMessage: '*Please Enter Valid Payroll Reference',
    isvalid: true
  }
};

export const ADD_WORKER_VALIDATIONS = {
  first_name: {
    required: false,
    errorMessage: '*Please Enter Valid First Name',
    isvalid: true
  },
  last_name: {
    required: false,
    errorMessage: '*Please Enter Valid Last Name',
    isvalid: true
  },
  date_of_birth: {
    required: true,
    errorMessage: '*Please Enter Valid DOB',
    isvalid: true
  },
  national_insurance_number: {
    required: false,
    errorMessage: '*Please Enter Valid National Insurance Number',
    isvalid: true
  },
  employee_id: {
    required: true,
    errorMessage: '*Please Enter Valid Employee Id',
    isvalid: true
  },
  tax_code: {
    required: false,
    errorMessage: '*Please Enter Valid Tax Code',
    isvalid: true
  },
  post_code: {
    required: false,
    errorMessage: '*Min 3 and Max 8 characters are allowed',
    isvalid: true
  },
  nationality: {
    required: false,
    errorMessage: '*Please Select Nationality',
    isvalid: true
  },
  orientation: {
    required: false,
    errorMessage: '*Please Select Sex',
    isvalid: true
  },
  email: {
    required: true,
    errorMessage: '*Please Enter Valid Email',
    isvalid: true
  },
  country_code: {
    required: false,
    errorMessage: '*Please Enter Valid Country Code',
    isvalid: true
  },
  mobile: {
    required: false,
    errorMessage: '*Please Enter Valid Mobile',
    isvalid: true
  },
  start_date: {
    required: true,
    errorMessage: '*Please Enter Valid Start Date',
    isvalid: true
  },
  assignment_date: {
    required: true,
    errorMessage: '*Please Enter Valid Assignment Date',
    isvalid: true
  },
  job_id: {
    required: true,
    errorMessage: '*Please Select Role Type',
    isvalid: true
  },
  agency_id: {
    required: true,
    errorMessage: '*Please Select Agency',
    isvalid: true
  },
  client_id: {
    required: true,
    errorMessage: '*Please Select Company',
    isvalid: true
  },
  payroll_ref: {
    required: true,
    errorMessage: '*Please Enter Valid Payroll Reference',
    isvalid: true
  },
  site_id: {
    required: true,
    errorMessage: '*Please Select Site',
    isvalid: true
  },
  transport: {
    required: false,
    errorMessage: '*Please Select transport',
    isvalid: true
  },
  pension: {
    required: false,
    errorMessage: '*Please Select pension',
    isvalid: true
  },
  house_number: {
    required: false,
    errorMessage: '*Please Enter Valid House Number',
    isvalid: true
  },
  student_visa: {
    required: false,
    errorMessage: '*Please Select Student Visa',
    isvalid: true
  },
  other_assignment: {
    required: false,
    errorMessage: '*Please Select Other Assignment',
    isvalid: true
  },
  workers_supervisor_status: {
    required: false,
    errorMessage: '*Please Select Supervisor Status',
    isvalid: true
  }
};

export const ADD_ASSOCIATION_VALIDATIONS = {
  client_id: {
    required: true,
    errorMessage: '*Please Enter Valid Client',
    isvalid: true
  },
  agency_id: {
    required: true,
    errorMessage: '*Please Enter Valid Agency',
    isvalid: true
  },
  margin: {
    required: true,
    errorMessage: '*Please Enter Valid Margin',
    isvalid: true
  },
  overtime_margin: {
    required: false,
    errorMessage: '*Please Enter Valid Overtime Margin',
    isvalid: true
  },
  transport_fee: {
    required: false,
    errorMessage: '*Please Enter Valid Transport Fee',
    isvalid: true
  },
  ssp: {
    required: false,
    errorMessage: '*Please Enter Valid SSP',
    isvalid: true
  },
  training_margin: {
    required: false,
    errorMessage: '*Please Enter Valid Training Margin',
    isvalid: true
  },
  // training_hours: {
  //   required: false,
  //   errorMessage: '*Please Enter Valid Training Hours',
  //   isvalid: true
  // },
  currency: {
    required: true,
    errorMessage: '*Please Enter Valid Currency',
    isvalid: true
  },
  bh_margin: {
    required: false,
    errorMessage: '*Please Enter Valid Bh',
    isvalid: true
  },
  nsp_margin: {
    required: false,
    errorMessage: '*Please Enter Valid NSP',
    isvalid: true
  },
  internal_standard_margin: {
    required: false,
    errorMessage: '*Please Enter Valid Internal Standard',
    isvalid: true
  },
  internal_overtime_margin: {
    required: false,
    errorMessage: '*Please Enter Valid Internal Overtime',
    isvalid: true
  },
  internal_permanent_margin: {
    required: false,
    errorMessage: '*Please Enter Valid Internal Permanent',
    isvalid: true
  },
  suspension_margin: {
    required: false,
    errorMessage: '*Please Enter Valid Suspension',
    isvalid: true
  },
  holiday_activation: {
    required: false,
    errorMessage: '*Please Select Valid Holiday activation',
    isvalid: true
  },
  holiday_cost_removed: {
    required: false,
    errorMessage: '*Please Select Valid Holiday cost removed',
    isvalid: true
  }
};

export const ADD_RATE_CARD_VALIDATIONS = {
  name: {
    required: true,
    errorMessage: '*Please Enter Valid Job Name',
    isvalid: true
  },
  currency: {
    required: true,
    errorMessage: '*Please Enter Valid Currency',
    isvalid: true
  },
  pay_per_hour: {
    required: true,
    errorMessage: '*Please Enter Valid Pay Per Hour',
    isvalid: true
  },
  insurance_rate: {
    required: true,
    errorMessage: '*Please Enter Valid Insurance Rate',
    isvalid: true
  },
  holiday_pay_rate: {
    required: true,
    errorMessage: '*Please Enter Valid Holiday Pay Rate',
    isvalid: true
  },
  apprenticeship_rate: {
    required: true,
    errorMessage: '*Please Enter Valid Apprenticeship Rate',
    isvalid: true
  },
  pension_rate: {
    required: true,
    errorMessage: '*Please Enter Valid First Pension Rate',
    isvalid: true
  },
  full_time_hours: {
    required: true,
    errorMessage: '*Please Enter Valid Full Time Hours Rate',
    isvalid: true
  },
  overtime_pay: {
    required: true,
    errorMessage: '*Please Enter Valid Over Time Pay Rate',
    isvalid: true
  }
};

export const ADD_DYNAMIC_RATE_CARD_VALIDATIONS = {
  pay_per_hour: {
    required: true,
    errorMessage: '*Please Enter Valid Pay Per Hour',
    isvalid: true
  },
  insurance_rate: {
    required: true,
    errorMessage: '*Please Enter Valid Insurance Rate',
    isvalid: true
  },
  holiday_pay_rate: {
    required: true,
    errorMessage: '*Please Enter Valid Holiday Pay Rate',
    isvalid: true
  },
  apprenticeship_rate: {
    required: true,
    errorMessage: '*Please Enter Valid Apprenticeship Rate',
    isvalid: true
  },
  pension_rate: {
    required: true,
    errorMessage: '*Please Enter Valid First Pension Rate',
    isvalid: true
  },
  full_time_hours: {
    required: true,
    errorMessage: '*Please Enter Valid Full Time Hours Rate',
    isvalid: true
  },
  overtime_pay: {
    required: true,
    errorMessage: '*Please Enter Valid Over Time Pay Rate',
    isvalid: true
  }
};

export const ADD_SHIFT_VALIDATION = {
  name: {
    required: true,
    errorMessage: '*Please Enter Valid Shift Name',
    isvalid: true
  }
};

export const ADD_CLIENT_USER_VALIDATION = {
  name: {
    required: true,
    errorMessage: '*Please Enter Valid Name',
    isvalid: true
  },
  email: {
    required: true,
    errorMessage: '*Please Enter Valid Email',
    isvalid: true
  },
  country_code: {
    required: true,
    errorMessage: '*Please Enter Valid Country Code',
    isvalid: true
  },
  mobile: {
    required: true,
    errorMessage: '*Please Enter Valid Mobile',
    isvalid: true
  },
  client_role: {
    required: true,
    errorMessage: '*Please Enter Valid Client Role',
    isvalid: true
  },
  id: {
    required: true,
    errorMessage: '*Please Enter Valid Client Role',
    isvalid: true
  }
};
export const ADD_CLIENT_USER_MESSAGETYPE_VALIDATION = {
  name: {
    required: true,
    errorMessage: '*Please Enter Valid Name',
    isvalid: true
  },
  email: {
    required: true,
    errorMessage: '*Please Enter Valid Email',
    isvalid: true
  },
  country_code: {
    required: true,
    errorMessage: '*Please Enter Valid Country Code',
    isvalid: true
  },
  mobile: {
    required: true,
    errorMessage: '*Please Enter Valid Mobile',
    isvalid: true
  },
  client_role: {
    required: true,
    errorMessage: '*Please Enter Valid Client Role',
    isvalid: true
  }
};

export const ADD_JOB_VALIDATIONS = {
  name: {
    required: true,
    errorMessage: '*Please Enter Valid Name',
    isvalid: true
  },
  departmentId: {
    required: true,
    errorMessage: '*Please Select Valid Department',
    isvalid: true
  },
  siteId: {
    required: true,
    errorMessage: '*Please Select Valid Site',
    isvalid: true
  },
  rateCardId: {
    required: false,
    errorMessage: '*Please Select Valid Rate Card',
    isvalid: true
  },
  type: {
    required: true,
    errorMessage: '*Please Select Valid Role Type',
    isvalid: true
  },
  shiftId: {
    required: true,
    errorMessage: '*Please Select Valid Shift',
    isvalid: true
  },
  hoursPerWeek: {
    required: true,
    errorMessage: '*Please Select Valid Hours Per Week',
    isvalid: true
  }
};

export const ADD_BOOKING_VALIDATIONS = {
  department_id: {
    required: true,
    errorMessage: '*Please Select Valid Department',
    isvalid: true
  },
  shift_id: {
    required: true,
    errorMessage: '*Please Select Valid Shift',
    isvalid: true
  }
};

export const DAYS_VALIDATION = {
  1: {
    required: true,
    errorMessage: '*Please Enter Valid Number',
    isvalid: true
  },
  2: {
    required: true,
    errorMessage: '*Please Enter Valid Number',
    isvalid: true
  },
  3: {
    required: true,
    errorMessage: '*Please Enter Valid Number',
    isvalid: true
  },
  4: {
    required: true,
    errorMessage: '*Please Enter Valid Number',
    isvalid: true
  },
  5: {
    required: true,
    errorMessage: '*Please Enter Valid Number',
    isvalid: true
  },
  6: {
    required: true,
    errorMessage: '*Please Enter Valid Number',
    isvalid: true
  },
  7: {
    required: true,
    errorMessage: '*Please Enter Valid Number',
    isvalid: true
  }
};
export const MESSAGE_CREATE_VALIDATIONS = {
  name: {
    required: true,
    errorMessage: '*Please Enter Template Name',
    isvalid: true
  },
  title: {
    required: true,
    errorMessage: '*Please Enter Template Title',
    isvalid: true
  },
  from: {
    required: true,
    errorMessage: '*Please Enter From',
    isvalid: true
  }
};
export const DESIGN_TRANSLATE_VALIDATIONS = {
  title: {
    required: true,
    errorMessage: '*Please Enter title',
    isvalid: true
  }
};
export const TRANSLATE_CREATE_VALIDATIONS = {
  from: {
    required: true,
    errorMessage: '*Please Enter From',
    isvalid: true
  }
};
export const MESSAGE_UPDATE_VALIDATIONS = {
  from: {
    required: true,
    errorMessage: '*Please Enter From',
    isvalid: true
  }
};

export const ADD_TRAINING_RULE = {
  site: {
    required: true,
    errorMessage: '*Please Select Valid Site',
    isvalid: true
  },
  performanceNumber: {
    required: true,
    errorMessage: '*Please Enter Valid Performance Number',
    isvalid: true
  },
  weekNumber: {
    required: true,
    errorMessage: '*Please Enter Valid Week Number',
    isvalid: true
  },
  checkWeek: {
    required: true,
    errorMessage: '*Please Enter Valid Check Week Number',
    isvalid: true
  },
  maxTrainingHours: {
    required: true,
    errorMessage: '*Please Enter Valid Max Training Hours',
    isvalid: true
  },
  credit: {
    required: true,
    errorMessage: '*Please Enter Valid Credit(%)',
    isvalid: true
  }
};

export const TIME_AND_ATTENDANCE_STATUS = {
  APPROVED: 'Approved',
  PROCESSING: 'Processing',
  PROCESSED: 'Processed'
};

export const PERMISSIONS = {
  1: ['view'],
  2: ['view', 'create'],
  3: ['view', 'create', 'edit'],
  4: ['view', 'create', 'edit', 'delete']
};

export const CHART_COLORS = {
  EXCELLENT: '#42CC83',
  GOOD: '#54DF96',
  AVERAGE: '#FFD058',
  BELLOW: '#EDC04F',
  POOR: '#EA403D'
};

export const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const REPEAT_SHIFT_BOOKIING_TYPE = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const YEARS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const YEARS_ACTIVATION_AND_VALUE = {
  1: { activate: '0', value: '' },
  2: { activate: '0', value: '' },
  3: { activate: '0', value: '' },
  4: { activate: '0', value: '' },
  5: { activate: '0', value: '' },
  6: { activate: '0', value: '' },
  7: { activate: '0', value: '' },
  8: { activate: '0', value: '' },
  9: { activate: '0', value: '' },
  10: { activate: '0', value: '' }
};

export const LANGUAGE_OPTION = [
  { name: 'Albanian', code: 'sq' },
  { name: 'Bulgarian', code: 'bg' },
  { name: 'Czech', code: 'cs' },
  { name: 'English', code: 'en' },
  { name: 'Estonian', code: 'et' },
  { name: 'Hindi', code: 'hi' },
  { name: 'Hungarian', code: 'hu' },
  { name: 'Italian', code: 'it' },
  { name: 'Latvian', code: 'lv' },
  { name: 'Nepali', code: 'ne' },
  { name: 'Polish', code: 'pl' },
  { name: 'Portuguese', code: 'pt' },
  { name: 'Romanian', code: 'ro' },
  { name: 'Spanish', code: 'es' },
  { name: 'Ukrainian', code: 'uk' },
  { name: 'French', code: 'fr' },
  { name: 'Lithuanian', code: 'lt' },
  { name: 'Slovak', code: 'sk' },
  { name: 'Slovanian', code: 'sl' },
  { name: 'Urdu', code: 'ur' }
];

export const TRANSLATE_LANGUAGE = [
  'bg',
  'cs',
  'es',
  'et',
  'fr',
  'hi',
  'hu',
  'it',
  'lt',
  'lv',
  'ne',
  'pl',
  'pt',
  'ro',
  'sk',
  'sl',
  'sq',
  'uk',
  'ur'
];
export const CurrentYear = '2022-2023';
export const CHART_COLORS_FOR_AGENCY = [
  '#EF7348',
  '#FA914D',
  '#FCCF58',
  '#2EA2E4',
  '#298CEC',
  '#164AAD',
  '#707ED9',
  '#8C52FF'
];

export const PAGE_TITLE = {
  DASHBOARD: 'ClearVue | Dashboard',
  REGION: 'ClearVue | Region',
  SITES: 'ClearVue | Sites',
  DEPARTMENT: 'ClearVue | Department',
  SHIFT_TYPE: 'ClearVue | Shift Type',
  ROLE_TYPE: 'ClearVue | Role Type',
  WORKERS: 'ClearVue | Workers',
  ASSOCIATIONS: 'ClearVue | Associations',
  PAYROLL: 'ClearVue | Payroll',
  SHIFT_BOOKING: 'ClearVue | Shift Booking',
  ATTENDANCE: 'ClearVue | Payroll',
  ATTENDANCE_DETAILS: 'ClearVue | Attendence Details',
  FORGOT_PASSWORD: 'ClearVue | Forgot Password',
  LOGIN: 'ClearVue | Login',
  PROCESSED_ATTENDANCE: 'ClearVue | Processed Attendence',
  RESET_PASSWORD: 'ClearVue | Reset Password',
  USERS: 'ClearVue | Users',
  VIEW_MORE: 'ClearVue | View More',
  RATE_CARD: 'ClearVue | Rate Card',
  AGENCY: 'ClearVue | Agency',
  CLIENT: 'ClearVue | Client',
  DEMOGRAPHICS: 'ClearVue | Demographics',
  MESSAGING: 'ClearVue | Messaging',
  SURVEYS: 'ClearVue | Surveys',
  TRENDS_ANALYSIS: 'ClearVue | Trends Analysis',
  SYSTEM_USERS: 'ClearVue | System Users',
  COMPLIANCE: 'ClearVue | Compliance',
  AGENCY_MANAGEMENT: 'ClearVue | Agency Management',
  TRAINING: 'ClearVue | Training'
};

export const CIRCULAR_CHART_COLORS = {
  PATH_COLOR: '#124684',
  TEXT_COLOR: '#002D62',
  TRAIL_COLOR: '#EF7348'
};

export const DASHBOARD_TEXT_COLOR = {
  TEXT_COLOR: '#F17F6D'
};

export const HEADER_COLORS = {
  ACTIVITY_COLOR: '#479AFF',
  WORKFORCE_COLOR: '#EF7348',
  LEAVERS_COLOR: '#4670A2'
};

export const genderList = ['Male', 'Female', 'Other'];
export const YesNoDropdownList = ['YES', 'NO'];
export const DaySelectionList = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
export const BookingFormat = ['HEADS', 'HOURS'];
export const TaxYeraSelectionList = [
  { label: '6 Months', value: '0.5' },
  { label: '1 Year', value: '1' },
  { label: '2 Year', value: '2' },
  { label: '3 Year', value: '3' },
  { label: '4 Year', value: '4' },
  { label: '5 Year', value: '5' },
  { label: '6 Year', value: '6' },
  { label: '7 Year', value: '7' },
  { label: '8 Year', value: '8' },
  { label: '9 Year', value: '9' },
  { label: '10 Year', value: '10' },
  { label: '11 Year', value: '11' },
  { label: '12 Year', value: '12' },
  { label: '13 Year', value: '13' },
  { label: '14 Year', value: '14' },
  { label: '15 Year', value: '15' },
  { label: '16 Year', value: '16' },
  { label: '17 Year', value: '17' },
  { label: '18 Year', value: '18' },
  { label: '19 Year', value: '19' },
  { label: '20 Year', value: '20' }
];
export const RoleTypeSelectionList = [
  'Full Time 5 Days',
  'Full Time 4 on 4 off',
  'Full Time 4 on 3 off',
  'Full Time 3 on 3 off',
  'Part Time',
  'Weekend'
];

export const RoleTypeList = [
  { label: 'Full Time 5 Days', value: '1' },
  { label: 'Part Time', value: '2' },
  { label: 'Weekend', value: '3' },
  { label: 'Full Time 4 on 4 off', value: '4' },
  { label: 'Full Time 4 on 3 off', value: '5' },
  { label: 'Full Time 3 on 3 off', value: '6' }
];

export const BooleanValue = [
  { label: 'true', value: 'true' },
  { label: 'false', value: 'false' }
];

export const PreWeekSelectionList = [
  { label: '(0 Days) 0.00%', value: '0.00' },
  { label: '(1 Days) 0.39%', value: '0.39' },
  { label: '(2 Days) 0.78%', value: '0.78' },
  { label: '(3 Days) 1.17%', value: '1.17' },
  { label: '(4 Days) 1.56%', value: '1.56' },
  { label: '(5 Days) 1.96%', value: '1.96' },
  { label: '(6 Days) 2.36%', value: '2.36' },
  { label: '(7 Days) 2.77%', value: '2.77' },
  { label: '(8 Days) 3.17%', value: '3.17' },
  { label: '(9 Days) 3.59%', value: '3.59' },
  { label: '(10 Days) 4.00%', value: '4.00' },
  { label: '(11 Days) 4.42%', value: '4.42' },
  { label: '(12 Days) 4.84%', value: '4.84' },
  { label: '(13 Days) 5.26%', value: '5.26' },
  { label: '(14 Days) 5.69%', value: '5.69' },
  { label: '(15 Days) 6.12%', value: '6.12' },
  { label: '(16 Days) 6.56%', value: '6.56' },
  { label: '(17 Days) 7.00%', value: '7.00' },
  { label: '(18 Days) 7.44%', value: '7.44' },
  { label: '(19 Days) 7.88%', value: '7.88' },
  { label: '(20 Days) 8.33%', value: '8.33' },
  { label: '(21 Days) 8.79%', value: '8.79' },
  { label: '(22 Days) 9.24%', value: '9.24' },
  { label: '(23 Days) 9.70%', value: '9.70' },
  { label: '(24 Days) 10.17%', value: '10.17' },
  { label: '(25 Days) 10.64%', value: '10.64' },
  { label: '(26 Days) 11.11%', value: '11.11' },
  { label: '(27 Days) 11.59%', value: '11.59' },
  { label: '(28 Days) 12.07%', value: '12.07' },
  { label: '(29 Days) 12.55%', value: '12.55' },
  { label: '(30 Days) 13.04%', value: '13.04' },
  { label: '(31 Days) 13.54%', value: '13.54' },
  { label: '(32 Days) 14.04%', value: '14.04' },
  { label: '(33 Days) 14.54%', value: '14.54' },
  { label: '(34 Days) 15.04%', value: '15.04' },
  { label: '(35 Days) 15.56%', value: '15.56' },
  { label: '(36 Days) 16.07%', value: '16.07' },
  { label: '(37 Days) 16.59%', value: '16.59' },
  { label: '(38 Days) 17.12%', value: '17.12' },
  { label: '(39 Days) 17.65%', value: '17.65' },
  { label: '(40 Days) 18.18%', value: '18.18' },
  { label: '(41 Days) 18.72%', value: '18.72' },
  { label: '(42 Days) 19.27%', value: '19.27' },
  { label: '(43 Days) 19.82%', value: '19.82' },
  { label: '(44 Days) 20.37%', value: '20.37' },
  { label: '(45 Days) 20.93%', value: '20.93' },
  { label: '(46 Days) 21.50%', value: '21.50' },
  { label: '(47 Days) 22.07%', value: '22.07' },
  { label: '(48 Days) 22.64%', value: '22.64' },
  { label: '(49 Days) 23.22%', value: '23.22' },
  { label: '(50 Days) 23.81%', value: '23.81' }
];

export const PayTypeSelectionList = [
  'Bh',
  'Internal standard',
  'Internal overtime',
  'Internal permanent',
  'Overtime',
  'Standard',
  'Suspension',
  'Special bonus',
  'Standard bonus',
  'Sp',
  'Training',
  'Weekend'
];

export const BLUE_COLOR_SHADES = [
  '#6d9eff',
  '#5a92ff',
  '#4886FF',
  '#4179e6',
  '#3a6bcc',
  '#325eb3',
  '#2b5099',
  '#244380',
  '#1d3666'
];

export const CARDS_DATA = [
  {
    title: 'Workers 60+ Hours',
    value: 0,
    index: 1
  },
  {
    title: 'Multiple Occupancy',
    value: 0,
    index: 2
  },
  {
    title: 'Student Visa',
    value: 0,
    index: 3
  },
  {
    title: 'Consecutive days',
    value: 0,
    index: 4
  },
  {
    title: 'U18',
    value: 0,
    index: 5
  }
];

export const SPEND_HOUR_COLORS = ['#BB2F5D', '#2572FF'];
export const HEAD_LEAVERS_COLORS = ['#3BD2F7'];
export const RATING_STAR_COLOR = '#FFE234';
export const MIN_BARS_FOR_CHART = 8;

export const DATE_SAVE_TO_DB = (date) => {
  let newDate = '';
  newDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  return newDate;
};

export const DATE_TO_SHOW = (date) => {
  let newDate = '';
  newDate = moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
  return newDate;
};

export const NEW_CHART_COLOR = {
  BLUE: '#2572FF'
};

export const NEW_CHART_COLOR_ARRAY = [
  '#2572FF',
  '#2572FF',
  '#2572FF',
  '#2572FF',
  '#2572FF',
  '#2572FF',
  '#2572FF',
  '#2572FF'
];

export const SITE_MARGIN_VALIDATIONS = {
  margin: {
    required: true,
    errorMessage: '*Please Enter Valid Standard Margin',
    isvalid: true
  },
  overtime_margin: {
    required: false,
    errorMessage: '*Please Enter Valid Overtime Margin',
    isvalid: true
  },
  transport_fee: {
    required: false,
    errorMessage: '*Please Enter Valid Transport Fee',
    isvalid: true
  },
  training_margin: {
    required: false,
    errorMessage: '*Please Enter Valid Training Margin',
    isvalid: true
  },
  bh_margin: {
    required: false,
    errorMessage: '*Please Enter Valid BH Margin',
    isvalid: true
  },
  ssp: {
    required: false,
    errorMessage: '*Please Enter Valid SSP',
    isvalid: true
  },
  nsp_margin: {
    required: false,
    errorMessage: '*Please Enter Valid SP Margin',
    isvalid: true
  },
  internal_standard_margin: {
    required: false,
    errorMessage: '*Please Enter Valid Internal Standard',
    isvalid: true
  },
  internal_overtime_margin: {
    required: false,
    errorMessage: '*Please Enter Valid Internal Overtime',
    isvalid: true
  },
  internal_permanent_margin: {
    required: false,
    errorMessage: '*Please Enter Valid Internal Permanent',
    isvalid: true
  },
  suspension_margin: {
    required: false,
    errorMessage: '*Please Enter Valid Suspension',
    isvalid: true
  }
};
