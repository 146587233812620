/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@chakra-ui/react';
import { USER_TYPE } from '../../constants';
import { getSiteDropDownData } from '../../redux/action/site.action';
import Dropdown from '../Dropdown';
import {
  selectPerformanceDepartment,
  selectPerformanceShift,
  selectPerformanceSite
} from '../../redux/actions/performanceDashboard.action';

const SiteDropdownHeader = function ({
  validationObj = { isvalid: true },
  refresh = false,
  multiple = false,
  disabled = false,
  isHeader = false
}) {
  const dispatch = useDispatch();
  const [sites, setSites] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  const { userData, user_details } = useSelector((state) => state.authentication);
  const {
    selected_client_id,
    selected_performance_site_id,
    selected_performance_site_name,
    selected_performance_region_id
  } = useSelector((state) => state.agency);
  const getClientId = () => {
    switch (user_details.user_type_id) {
      case USER_TYPE.CLIENT_ADMIN:
        return userData.client_id;
      case USER_TYPE.AGENCY_ADMIN:
      case USER_TYPE.AGENCY_REGION_ADMIN:
      case USER_TYPE.AGENCY_SITE_ADMIN:
        return selected_client_id || userData.client_id;
      default:
        return selected_client_id || userData.client_id;
    }
  };
  useEffect(() => {
    let mounted = true;
    const fetchSiteData = async () => {
      const clientId = getClientId();
      const regionId =
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        user_details.user_type_id === USER_TYPE.REGION_ADMIN
          ? user_details.region_id
          : selected_performance_region_id;
      if (clientId) {
        const result = await getSiteDropDownData(clientId, regionId || null);

        if (mounted && result && result.ok) {
          setSites(result.data.sites);

          if (!selected_performance_site_id) {
            if (isHeader) {
              dispatch(selectPerformanceSite('', 'All Sites'));
            } else if (result.data.sites.length > 0) {
              const firstSite = result.data.sites[0];
              dispatch(selectPerformanceSite(firstSite.id, firstSite.name));
              dispatch(selectPerformanceShift(null, null));
              dispatch(selectPerformanceDepartment(null, null));
            }
          }
        } else if (mounted) {
          setSites([]);
          dispatch(selectPerformanceSite(null, null));
        }
      }
    };

    fetchSiteData();

    return () => {
      mounted = false;
    };
  }, [
    selected_client_id,
    userData.client_id,
    selected_performance_region_id,
    user_details.user_type_id,
    user_details.region_id,
    refresh,
    isHeader,
    selected_performance_site_id,
    dispatch
  ]);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh, validationObj]);

  const handleSiteSelect = (selectedOption) => {
    if (selected_performance_site_id !== selectedOption.value) {
      dispatch(selectPerformanceShift(null, null));
      dispatch(selectPerformanceDepartment(null, null));
    }
    setErrorMsg(null);
    setValid(true);

    // Handle "All Sites" case
    if (selectedOption.value === '') {
      dispatch(selectPerformanceSite('', 'All Sites'));
      return;
    }

    dispatch(selectPerformanceSite(selectedOption.value, selectedOption.label));
  };

  // Add "All Sites" option only for header
  const getOptions = () => {
    const options = sites.map((site) => ({
      label: site.name,
      value: site.id
    }));

    if (isHeader) {
      return [{ value: '', label: 'All Sites' }, ...options];
    }
    return options;
  };

  // Get the current value for the dropdown
  const getCurrentValue = () => {
    if (selected_performance_site_name) {
      return {
        label: selected_performance_site_name,
        value: selected_performance_site_id
      };
    }
    if (selected_performance_site_id === '' && isHeader) {
      return { label: 'All Sites', value: '' };
    }
    return {};
  };

  return (
    <>
      <Dropdown
        label={`Site${validationObj?.required ? '*' : ''}`}
        onSelect={handleSiteSelect}
        options={getOptions()}
        value={getCurrentValue()}
        placeholder="Select"
        multiple={multiple}
        isError={!isValid}
        disabled={disabled}
        isSearchable
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error!'}
      </Text>
    </>
  );
};

export default SiteDropdownHeader;
