/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@chakra-ui/react';
import { USER_TYPE } from '../../constants';
import { getDepartments } from '../../redux/action/department.action';
import Dropdown from '../Dropdown';
import { selectPerformanceDepartment } from '../../redux/actions/performanceDashboard.action';

const DepartmentDropdownHeader = function ({
  validationObj = { isvalid: true },
  refresh = false,
  multiple = false,
  disabled = false,
  isHeader = false
}) {
  const dispatch = useDispatch();
  const [departments, setDepartments] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  const { userData, user_details } = useSelector((state) => state.authentication);
  const {
    selected_client_id,
    selected_performance_site_id,
    selected_performance_department_id,
    selected_performance_department_name,
    selected_performance_region_id
  } = useSelector((state) => state.agency);

  useEffect(() => {
    let mounted = true;

    const fetchDepartmentData = async () => {
      // Helper function to determine region ID based on user type
      const getRegionId = () => {
        switch (user_details.user_type_id) {
          case USER_TYPE.SUPER_ADMIN:
            return selected_performance_region_id;
          case USER_TYPE.CLIENT_ADMIN:
            return selected_performance_region_id;
          case USER_TYPE.AGENCY_ADMIN:
            return selected_performance_region_id;
          case USER_TYPE.AGENCY_REGION_ADMIN:
            return user_details.region_id;
          case USER_TYPE.AGENCY_SITE_ADMIN:
            return selected_performance_region_id;
          case USER_TYPE.SITE_ADMIN:
            return null;
          default:
            return selected_performance_region_id || userData.region_id;
        }
      };

      // Helper function to determine client ID based on user type
      const getClientId = () => {
        switch (user_details.user_type_id) {
          case USER_TYPE.CLIENT_ADMIN:
            return userData.client_id;
          case USER_TYPE.AGENCY_ADMIN:
          case USER_TYPE.AGENCY_REGION_ADMIN:
          case USER_TYPE.AGENCY_SITE_ADMIN:
            return selected_client_id || userData.client_id;
          default:
            return selected_client_id || userData.client_id;
        }
      };

      // Helper function to determine site ID based on user type
      const getSiteId = () => {
        switch (user_details.user_type_id) {
          case USER_TYPE.SITE_ADMIN:
          case USER_TYPE.AGENCY_SITE_ADMIN:
            return user_details.site_id;
          default:
            return selected_performance_site_id || userData.site_id || null;
        }
      };

      const clientId = getClientId();
      if (clientId) {
        const result = await getDepartments(clientId, getSiteId(), getRegionId());

        if (mounted && result && result.ok) {
          setDepartments(result.data.department_list);

          // Only set first department if no department is selected and we have departments
          if (!selected_performance_department_id && result.data.department_list.length > 0) {
            const firstDepartment = result.data.department_list[0];
            dispatch(selectPerformanceDepartment(firstDepartment.id, firstDepartment.name));
          }
        } else if (mounted) {
          setDepartments([]);
          // Only clear selection if there are no departments
          if (!result?.data?.department_list?.length) {
            dispatch(selectPerformanceDepartment('', ''));
          }
        }
      }
    };

    fetchDepartmentData();

    return () => {
      mounted = false;
    };
  }, [
    selected_client_id,
    userData.client_id,
    selected_performance_site_id,
    selected_performance_region_id,
    user_details.user_type_id,
    user_details.site_id,
    user_details.region_id,
    refresh
  ]);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh, validationObj]);

  const handleDepartmentSelect = (selectedOption) => {
    setErrorMsg(null);
    setValid(true);

    // Handle "All Departments" case
    if (selectedOption.value === '') {
      dispatch(selectPerformanceDepartment('', ''));
      return;
    }

    dispatch(selectPerformanceDepartment(selectedOption.value, selectedOption.label));
  };

  // Add "All Departments" option only for header
  const getOptions = () => {
    const options = departments.map((department) => ({
      label: department.name,
      value: department.id
    }));

    if (isHeader) {
      return [{ value: '', label: 'All Departments' }, ...options];
    }
    return options;
  };

  // Get the current value for the dropdown
  const getCurrentValue = () => {
    if (selected_performance_department_name) {
      return {
        label: selected_performance_department_name,
        value: selected_performance_department_id
      };
    }
    if (selected_performance_department_id === '' && isHeader) {
      return { label: 'All Departments', value: '' };
    }
    return {};
  };

  return (
    <>
      <Dropdown
        label={`Department${validationObj?.required ? '*' : ''}`}
        onSelect={handleDepartmentSelect}
        options={getOptions()}
        value={getCurrentValue()}
        placeholder="Select"
        multiple={multiple}
        isError={!isValid}
        disabled={disabled}
        isSearchable
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error!'}
      </Text>
    </>
  );
};

export default DepartmentDropdownHeader;
